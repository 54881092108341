<template>
  <Layout>
    <el-table
      :data="itemInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        label="ID"
        width="100"
      />
      <el-table-column
        prop="ad_name"
        label="广告名称"
        width="250"
      />
      <el-table-column
        prop="ad_source"
        label="广告ID"
        width="400"
      />
      <el-table-column
        prop="ad_location"
        label="广告位置"
        width="150"
      />
      <el-table-column
        prop="status"
        label="状态"
        width="150"
      >
        <template #default="{row}">
          {{ 1===row.status ? '启用' : '禁用' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="total_coin_num"
        label="累计赠送金币"
        width="200"
      />
      <el-table-column
        prop="total_time_num"
        label="累计赠送时长"
        width="200"
      />
      <el-table-column
        fixed="right"
        label="操作"
      >
        <template v-slot="{ row }">
          <BTextButton
            title="编辑"
            icon="fa fa-pencil"
            @click="handleEditItem(row)"
          />
          <BTextButton
            :loading="row.loading"
            :title="row.status === 1 ? '启用' : '禁用'"
            :icon="`fa ${row.status === 1 ? 'fa-check-circle' : 'fa-ban'}`"
            @click="handleToggleItem(row)"
          />
          <BTextButton
            :loading="row.deleteLoading"
            title="删除"
            icon="fa fa-trash-o"
            @click="handleDeleteItem(row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </Layout>
</template>

<script>
import {
  queryAdvList,
  deleteAdvItem,
  toggleAdvItem
} from '@/api/advManger'
export default {
  data () {
    return {
      itemInfo: {
        list: [],
        loading: false,
        total: 0
      },
      formData: {
        pageNum: 1,
        pageSize: 20
      }
    }
  },
  created () {
    this.getListData()
  },
  methods: {
    getListData () {
      this.itemInfo.loading = true
      queryAdvList({}).then(res => {
        if (res.code === 200) {
          console.log(res)
          this.itemInfo.list = res.data.list
        }
      })
        .finally(() => {
          this.itemInfo.loading = false
        })
    },
    handleEditItem (row) {
      this.$router.push({
        name: 'EditAdvertising',
        query: {
          id: row.id,
          isCreated: false
        }
      })
    },
    handleToggleItem (row) {
      const TIPS =
        row.status !== 1
          ? '此操作将启用广告， 是否继续?'
          : '此操作将禁用广告， 是否继续?'
      const NEXT_STATUS = row.status !== 1 ? 1 : 2
      this.$confirm(TIPS, '提示', { type: 'warning ' }).then(() => {
        this.$set(row, 'loading', true)
        toggleAdvItem({
          id: row.id,
          status: NEXT_STATUS
        })
          .then(res => {
            if (res.code === 200) {
              this.$set(row, 'status', NEXT_STATUS)
            }
          })
          .finally(() => {
            this.$set(row, 'loading', false)
          })
      })
    },
    handleDeleteItem (row) {
      this.$confirm('此操作将删除该广告, 是否继续', '提示', {
        type: 'warning '
      }).then(() => {
        this.$set(row, 'deleteLoading', true)
        deleteAdvItem({
          id: row.id
        })
          .then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.getListData()
            }
          })
          .finally(() => {
            this.$set(row, 'deleteLoading', false)
          })
      })
    }
  }
}
</script>
